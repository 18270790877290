import React, { useState } from 'react'
import ProjectCards from './ProjectCards'
import './projects.css'
import ongoing_project from './ongoing_projects.json'
import past_project from './past_projects.json'

function Projects() {

  const [ projects , setprojects] = useState(ongoing_project)

  return (
    <div className='projects_main'>
        <div className="project_title">
            <p>Projects</p>
        </div>

        <center><div className='project_button' id='home_button'>
            <div class="btn-group" role="group" aria-label="Basic example">
                <button id='past_pr' type="button" class="btn button_project past_pr" onClick={() => {
                document.getElementById("past_pr").style.background = "#fbf3d3";
                document.getElementById("past_pr").style.color = "#191815";
                document.getElementById("ongoing_pr").style.background = "#191815";
                document.getElementById("ongoing_pr").style.color = "#fbf3d3";
                setprojects(past_project);
                }}>
                    Completed Projects
                </button>
                <button id='ongoing_pr' type="button" class="btn button_project ongoing_pr" onClick={() => {
                document.getElementById("ongoing_pr").style.background = "#fbf3d3";
                document.getElementById("ongoing_pr").style.color = "#191815";
                document.getElementById("past_pr").style.background = "#191815";
                document.getElementById("past_pr").style.color = "#fbf3d3";
                setprojects(ongoing_project);
                }}>
                    Ongoing Projects
                </button>
          </div>
        </div></center>

        <div className="container project_cards" id='ongoingCards'>
            <div className="row">
                {
                projects
                && projects.map(projects => <div className="col-sm-6 colx" ><ProjectCards project_img={projects.project_img} project_title={projects.project_title} project_id={projects.id} project_photos={projects.project_photos} project_link={projects.project_link} /></div>)
                }
            </div>
        </div>
    </div>
  )
}

export default Projects