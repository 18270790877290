import './App.css';
import About from './Components/About';
import Contact from './Components/Contact';
import Home2 from './Components/Home2';
import Projects from './Components/Projects';

function App() {
  return (
    <div className="App">
      <div className="app_home2" id="app_home2">
        <Home2 />
      </div>
      <div className="app_projects" id="app_projects">
        <Projects />
      </div>
      <div className="app_about" id="app_about">
        <About />
      </div>
      <div className="app_contact" id="app_contact">
        <Contact />
      </div>
    </div>
  );
}

export default App;
