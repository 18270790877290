import React from 'react'
import './about.css'
import Dircard from './Dircard'
import dir_detail from './aboutx.json'

function About() {



  return (
    <div className='about_main'>
        <div className='container director_card detail_card'>
            <div className="row">
                <div className="col-sm dcard detailc">
                    <p className="detailc_title">25+</p>
                    <p className="detailc_con">Projects</p>
                </div>
                <div className="col-sm dcard">
                    <p className="detailc_title">100+</p>
                    <p className="detailc_con">Engineers</p>
                </div>
            </div>
        </div>
        <div className='container director_card mv_card'>
            <div className="row">
                <div className="col-sm dcard mvcard">
                    <p className="dcard_title">OUR VISION</p>
                    <p className="dcard_content">To be the Best-in- Class Engineering, Project Management and Construction Company Committed to Quality Work, Timely Completion and cost effective ensuring Customer Delight. To be recognized for being Mumbai's best construction company, delivering every project to the highest standard, with passion and professionalism. To achieve bringing dreams to life with our clients and all involved, while ensuring enjoyment is gained throughout the construction journey.</p>
                </div>
                <div className="col-sm dcard mvcard">
                    <p className="dcard_title">OUR MISSION</p>
                    <p className="dcard_content">To perform for our customers the highest level of quality construction services at fair and market competitive prices. To ensure the longevity of our company through repeat and referral business achieved by customer satisfaction in all areas including timeliness, attention to detail and service-minded attitudes. To maintain the highest levels of professionalism, integrity, honesty and fairness in our relationships with our suppliers, subcontractors, professional associates and customers. To achieve our objectives in an environment of fairness through courtesy to our Clients, Employees, Vendors and Society.</p>
                </div>
            </div>
        </div>
        <center><div className="about_content">
            <p>HAREE STAR INFRA has transformed in a short span of time from a civil contractor to a well groomed infrastructure development and construction company.
                We began this construction company with the ability and enthusiasm to with this name. Yet even at the start, we had a clear vision of what we wanted: a company with a core of skilled professionals that could consistently deliver high quality construction at a competitive price.
                At, HAREE STAR INFRA we strongly believe that passion and excellence is a matter of attitude and this reflects in each and everything we do and the way we do it. We believe that maintaining high standards of quality is the very core of achieving excellence, A customer centric approach and a deep commitment to honour the trust placed in us is our guiding principle.
                The Company has vision to provide the customers innovative architecture in affordable prices with international quality; HAREE STAR INFRA has established its capabilities in executing complex and challenging projects in all kinds of environment, having delivered projects in time bound schedules with international standards.
            </p>
        </div></center>
        <div className="about_title">
            <p>Our Key Features</p>
        </div>
        <div className='container director_card key_content_card'>
            <div className="row">
                <div className="col-sm dcard kccard">
                    <p className="dcard_title">HIGHLY VERSATILE</p>
                    <p className="dcard_content">We at Haree Star Infra do all kind of projects with different equipments and formwork. We do high-rise building as well as low-rise commercial building.</p>
                </div>
                <div className="col-sm dcard">
                    <p className="dcard_title">QUALITY OF WORK</p>
                    <p className="dcard_content">We at Haree Star Infra provide good quality of work under all circumstances.</p>
                </div>
                <div className="col-sm dcard">
                    <p className="dcard_title">TEAMWORK AND WORKABILITY</p>
                    <p className="dcard_content">We at Haree Star Infra provide the best team possible for the venture.</p>
                </div>
            </div>
        </div>
        <div className="about_title a2">
            <p>About Us</p>
        </div>
        <div className='container director_card'>
            <div className="row">
                {
                dir_detail
                && dir_detail.map(details => <div className="col-sm dcard" ><Dircard dir_image={details.dir_image} dir_name={details.dir_name} pos_detail={details.pos_detail} /></div>)
                }
                {/* <div className="col-sm dcard">
                    <Dircard dir_image="npatel.jpeg" dir_name="Naresh Patel" pos_detail="Managing Director" />
                </div>
                <div className="col-sm dcard">
                    <Dircard dir_image="dgadhiya.jpeg" dir_name="Dipesh Gadhiya" pos_detail="Managing Director" />
                </div>
                <div className="col-sm dcard">
                    <Dircard dir_image="haresh.jpeg" dir_name="Haresh Patel" pos_detail="Managing Director" />
                </div> */}
                {/* <div className="col-sm dcard">
                    <Dircard dir_image="npatel.jpeg" dir_name="Naresh Patel" pos_detail="Managing Director" />
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default About