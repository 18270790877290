import React, { Component } from 'react';
import './projectCards.css';

export class ProjectCards extends Component {

  render() {
    let {project_img, project_title, project_id, project_photos, project_link} = this.props;

  let photos_arr = project_photos.split(",");

  return (
    <>
    <div className='projects_card_main'>
        <div class="card card_main text-bg-dark" data-aos="zoom-out-up" data-bs-toggle="offcanvas" data-bs-target={"#offcanvasWithBothOptions"+String(project_id)} aria-controls="offcanvasWithBothOptions">
          <div className="card_image">
            <img src={project_img} alt="project_image" />
          </div>
          <div class="card-img-overlay card_title_main">
            <h5 class="card_title">{project_title}</h5>

          </div>
        </div>
        <div class="project_offcanvas offcanvas offcanvas-start" data-bs-scroll="true" tabindex="-1" id={"offcanvasWithBothOptions"+String(project_id)} aria-labelledby="offcanvasWithBothOptionsLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title offcanvas_title" id="offcanvasWithBothOptionsLabel">{project_title}</h5>
            <button type="button" class="btn-close offcanvas_close_btn" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <div class="offcanvas-body offcanvas_body">
            <a href={project_link} target="_blank" rel="noopener noreferrer"><button className="brochure_link">Brochure</button></a>
            <div className="row">
                {
                  photos_arr
                  && photos_arr.map(photos => <div className="pro_photos"><img src={photos} alt="ptoject_photos" loading='lazy' /></div> )
                }
            </div>
          </div>
        </div>
    </div>
    </>
  );
  }
}

export default ProjectCards