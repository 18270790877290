import React from 'react'
import './home2.css'
import sample from './homeVideo2.mp4'

function Home2() {

  // window.onscroll = function(){scrollFunction()};

  // 338 80

  // let scalex;
  // function scrollFunction(){
  //   if (window.scrollY>0){
  //     if (window.scrollY/(window.screen.height-15)<1){
  //       let scalex = 1 - window.scrollY/window.screen.height + 0.1;
  //       document.getElementById("home_main").style.transform = "scale(1,"+scalex+")";
  //       // document.getElementById("home_text_1").style.transform = "scale("+scale2+","+scale2+")";
  //     }
  //     // if (window.scrollY>500){
  //       document.getElementById("home_text_1").style.visibility = "hidden";
  //     // }
  //     document.getElementById("home_text_p").style.visibility = "hidden";
  //   }
  //   else{
  //     document.getElementById("home_main").style.transform = "scale(1,1)";
  //     document.getElementById("home_text_1").style.transform = "scale(1,1)";
  //     document.getElementById("home_text_p").style.visibility = "visible";
  //   }
  // }

  // const divx = document.getElementById('home_main');
  // window.addEventListener('scroll', function(){
  //   let pscroll = window.scrollY;
  //   let min_height = 100;
  //   let max_height = 200;
  //   let height2 = pscroll*(max_height-min_height)+min_height;
  //   divx.style.height = height2 + 'px';
  // })

  function navbar_shrink(){
    document.getElementById("home_main").style.height = "80px";
    document.getElementById("home_text_p").style.display = "none";
    document.getElementById("home_button").style.display = "none";
    document.getElementById("bg_video_main").style.filter = "blur(3px)";
    document.getElementById("home_fa").style.visibility = "visible";
  }

  function about_page(){
    document.getElementById("app_projects").style.display = "none";
    document.getElementById("app_about").style.display = "block";
    document.getElementById("drop_about").classList.add('activex');
    document.getElementById("drop_project").classList.remove('activex');
    document.getElementById("drop_contact").classList.remove('activex');
  }

  function project_page(){
    document.getElementById("app_projects").style.display = "block";
    document.getElementById("app_about").style.display = "none";
    document.getElementById("drop_project").classList.add('activex');
    document.getElementById("drop_about").classList.remove('activex');
    document.getElementById("drop_contact").classList.remove('activex');
  }

  function contact_page(){
    document.getElementById("app_about").style.display = "none";
    document.getElementById("app_projects").style.display = "none";
    document.getElementById("app_contact").style.display = "block";
    document.getElementById("drop_contact").classList.add('activex');
    document.getElementById("drop_about").classList.remove('activex');
    document.getElementById("drop_project").classList.remove('activex');
  }

  function back_to_home(){
    document.getElementById("home_main").style.height = "100vh";
    document.getElementById("home_text_p").style.display = "block";
    document.getElementById("home_button").style.display = "block";
    document.getElementById("bg_video_main").style.filter = "blur(0px)";
    document.getElementById("home_fa").style.visibility = "hidden";
    document.getElementById("app_about").style.display = "none";
    document.getElementById("app_projects").style.display = "none";
    document.getElementById("app_contact").style.display = "bloack";
  }



  return (
    <div className='home_main fixed-top' id='home_main'>
      <video className='videoTag' id='bg_video_main' autoPlay loop muted>
        <source src={sample} type='video/mp4' />
      </video>
        <div className="home_text" id='home_text'>
            <center><div className="home_text_1" id='home_text_1'></div></center>
            <div className="home_text_2" id='home_text_2' data-aos="flip-up">
                <p id='home_text_p'>
                  To be the Best-in-Class Engineering, <b>Project Management</b> and <br />
                  <b>Construction Company</b> Committed to <b>Quality Work, Timely Completion</b> <br />
                  and <b>cost effective</b> ensuring Customer Delight
                </p>     
            </div>
            <div className='home_button' id='home_button'>
              <div class="btn-group button_grp_home" role="group" aria-label="Basic example">
                <button type="button" class="btn button_x but_about" onClick={() => { navbar_shrink(); about_page();}}>
                  <p>About Us</p>
                  <img src="aboutDirect.png" alt="about_redirect" />
                </button>
                <button type="button" class="btn button_x but_projects" onClick={() => { navbar_shrink(); project_page();}}>
                  <p>View Projects</p>
                  <img src="projectDirect.png" alt="projects_redirect" />
                </button>
                {/* <button type="button" class="btn button_x but_contact" onClick={() => { navbar_shrink(); contact_page();}}>CONTACT US</button> */}
              </div>
            </div>
        </div>
        <div className="dropdown home_fa" id="home_fa" data-bs-toggle="dropdown" aria-expanded="false">
          <div className="homex" id="homex"></div>
          {/* <i class="fa-solid fa-house fa-xl"/> */}
          <div className="dropdown-menu dmenu">
            <ul class="home_dropdown_menu" data-popper-placement="bottom">
              <li><button class="dropdown-item dropdown_it" onClick={back_to_home} type="button">Home</button></li>
              <li><button class="dropdown-item dropdown_it" id="drop_project" onClick={() => { navbar_shrink(); project_page();}} type="button">Projects</button></li>
              <li><button class="dropdown-item dropdown_it" id="drop_about" onClick={() => { navbar_shrink(); about_page();}} type="button">About Us</button></li>
              <li><button class="dropdown-item dropdown_it" id="drop_contact" onClick={() => { navbar_shrink(); contact_page();}} type="button">Contact Us</button></li>
            </ul>
          </div>
        </div>
        {/* onClick={backToTop} */}
    </div>
  )
}

export default Home2