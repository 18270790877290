import React, { useState } from "react";
import "./contact.css";

function Contact() {

  const msg = "hi aryan";

  const [alert, setAlert] = useState(null);

  const showAlert = () => {
    setAlert({
      msg: msg,
    });
    setTimeout(() => {
      setAlert(null);
    }, 2000);
  }

  const set_alert = () => {
    let send_btn = document.getElementById("send_btn");
    showAlert();
  }

  return (
    <>
      <div className="contact_main">
        <div class="main-content">
          <div class="center box">
            <div class="content">
              <div class="place">
                <span class="fas footerFirst fa-map-marker-alt"></span>
                <span class="text">B/401, Aagam Shopping World, Vesu, Surat, Gujarat - 375007</span>
              </div>
              <div class="email">
                <span class="fas fa-envelope"></span>
                <span class="text">hareestarinfra123@gmail.com</span>
              </div>
            </div>
          </div>

          <div class="right box">
            <div class="content">
              <form action="#">
                <div class="email">
                  <div class="text formTxt">Email</div>
                  <input type="email" required disabled />
                </div>
                <div class="msg">
                  <div class="text formTxt">Message</div>
                  <textarea rows="2" cols="25" required disabled></textarea>
                </div>
                {/* <div class="alert alert-success" id="send_report" role="alert"></div> */}
                <div class="btnx" id="send_btn">
                  <button type="submit" onClick={set_alert} disabled>Send</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="bottom">
          <center>
            {/* <span class="credit">
              Created By <a href="#">aryan16x</a> |{" "}
            </span> */}
            <span class="far fa-copyright"></span>
            <span className="credit">
              {" "}
              2022 Hareestar Infra.
            </span>
          </center>
        </div>
      </div>
    </>
  );
}

export default Contact;
