// import { render } from '@testing-library/react';
import React from 'react'
import './dircard.css'

export class Dircard extends React.Component {

  render(){
  let {dir_image,dir_name,pos_detail} = this.props;

  return (
    <div className='dircard_main'>
        <div className="dir_img">
            <img src={dir_image} alt="director_img" />
        </div>
        <div className="dir_name">
            <h3>{dir_name}</h3>
            <h5>{pos_detail}</h5>
        </div>
    </div>
  );
}
}

export default Dircard